.mat-mdc-tab-nav-bar {
  .mat-mdc-tab-links {
    gap: 1.5rem;
  }

  .mat-mdc-tab-link {
    padding: 0;
    min-width: fit-content;

    .mdc-tab__content {
      margin: 0;
      align-items: flex-end;
      padding-bottom: 0.375rem;

      .mdc-tab__text-label {
        padding: 0;
      }
    }
  }

  .mat-mdc-tab-link:not(.mdc-tab--stacked) {
    --mdc-secondary-navigation-tab-container-height: 2rem;
  }

  .mat-mdc-tab-link-container {
    width: fit-content;
    flex-grow: 0;
    position: relative;
    padding-bottom: 0.125rem;

    &::after {
      content: '';
      position: absolute;
      bottom: 0;
      left: 0;
      right: 0;
      background: var(--wzb-bo-border-color);
      height: 0.125rem;
      border-radius: 2px;
    }
  }
}
