/* stylelint-disable scss/at-extend-no-missing-placeholder */
@mixin wzbBoNavMenu(
  $background,
  $text,
  $itemBackground,
  $itemContrast,
  $actionBackground,
  $actionContrast
) {
  .wzb-bo-navmenu {
    & > * {
      box-sizing: content-box;
    }

    background-color: $background;
    color: $text;

    &__menu-logo {
      margin: 2.5rem auto 0;
    }

    &__link--selected {
      font-weight: 600;

      .navmenu__link__icon {
        font-weight: normal;
        background-color: $itemBackground !important;
        color: $itemContrast !important;
      }
    }

    &__footer__link {
      @extend .wzb-bo-navmenu__link;

      .navmenu__link__icon {
        background-color: $actionBackground !important;
        color: $actionContrast !important;
      }

      &:not(:last-child) {
        margin-bottom: 0.75rem !important;
      }

      &:hover {
        .navmenu__link__icon {
          background-color: $actionBackground !important;
          color: $actionContrast !important;
        }
      }
    }

    &__link {
      min-height: 2.75rem;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      color: $itemContrast;

      &:not(:last-child) {
        margin-bottom: 0.75rem;
      }

      &:hover {
        .navmenu__link__icon {
          background-color: $itemBackground !important;
          color: $itemContrast !important;
        }
      }
    }

    .navmenu__footer__arrow-toggle {
      background-color: $itemBackground !important;
      color: $itemContrast !important;
    }
  }

  .navmenu__link__panel {
    min-height: 0 !important;
  }
}
