$wzb-font-black: rgb(0, 0, 40);

$wzb-black: rgb(0, 0, 0);
$wzb-black-80: rgb(0 0 0 / 80%);
$wzb-black-60: rgb(0 0 0 / 60%);
$wzb-black-40: rgb(0 0 0 / 40%);
$wzb-black-20: rgb(0 0 0 / 20%);
$wzb-black-8: rgb(0 0 0 / 8%);

$wzb-white: rgb(255, 255, 255);
$wzb-white-80: rgb(255 255 255 / 80%);
$wzb-white-60: rgb(255 255 255 /60%);
$wzb-white-40: rgb(255 255 255 / 40%);
$wzb-white-20: rgb(255, 255, 255, 0.8);
$wzb-white-8: rgb(255, 255, 255, 0);

$wzb-violet: rgb(75, 69, 178);
$wzb-violet-80: rgb(75 69 178 / 80%);
$wzb-violet-60: rgb(75 69 178 / 60%);
$wzb-violet-40: rgb(75 69 178 / 40%);
$wzb-violet-20: rgb(75 69 178 / 20%);
$wzb-violet-8: rgb(75 69 178 / 8%);

$wzb-carbon: rgb(15, 42, 71);
$wzb-carbon-80: rgb(15 42 71 / 80%);
$wzb-carbon-60: rgb(15 42 71 / 60%);
$wzb-carbon-40: rgb(15 42 71 / 40%);
$wzb-carbon-20: rgb(15 42 71 / 20%);
$wzb-carbon-8: rgb(15 42 71 / 8%);

$wzb-pink: rgb(255, 127, 137);
$wzb-pink-80: rgb(255 127 137 / 80%);
$wzb-pink-60: rgb(255 127 137 / 60%);
$wzb-pink-40: rgb(255 127 137 / 40%);
$wzb-pink-20: rgb(255 127 137 / 20%);
$wzb-pink-8: rgb(255 127 137 / 8%);

$wzb-electric: rgb(0, 98, 255);
$wzb-electric-80: rgb(0 98 255 / 80%);
$wzb-electric-60: rgb(0 98 255 / 60%);
$wzb-electric-40: rgb(0 98 255 / 40%);
$wzb-electric-20: rgb(0 98 255 / 20%);
$wzb-electric-8: rgb(0 98 255 / 8%);

$wzb-blue-jean: rgb(52, 134, 199);
$wzb-blue-jean-80: rgb(52 134 199 / 80%);
$wzb-blue-jean-60: rgb(52 134 199 / 60%);
$wzb-blue-jean-40: rgb(52 134 199 / 40%);
$wzb-blue-jean-20: rgb(52 134 199 / 20%);
$wzb-blue-jean-8: rgb(52 134 199 / 8%);

$wzb-turchese: rgb(129, 209, 224);
$wzb-turchese-80: rgb(129 209 224 / 80%);
$wzb-turchese-60: rgb(129 209 224 / 60%);
$wzb-turchese-40: rgb(129 209 224 / 40%);
$wzb-turchese-20: rgb(129 209 224 / 20%);
$wzb-turchese-8: rgb(129 209 224 / 8%);

$wzb-acid: rgb(198, 217, 47);
$wzb-acid-80: rgb(198 217 47 / 80%);
$wzb-acid-60: rgb(198 217 47 / 60%);
$wzb-acid-40: rgb(198 217 47 / 40%);
$wzb-acid-20: rgb(198 217 47 / 20%);
$wzb-acid-8: rgb(198 217 47 / 8%);

$wzb-sanguine: rgb(242, 100, 41);
$wzb-sanguine-80: rgb(242 100 41/ 80%);
$wzb-sanguine-60: rgb(242 100 41/ 60%);
$wzb-sanguine-40: rgb(242 100 41/ 40%);
$wzb-sanguine-20: rgb(242 100 41/ 20%);
$wzb-sanguine-8: rgb(242 100 41/ 8%);

$wzb-pastel-pink: rgb(252, 197, 207);
$wzb-pastel-pink-80: rgb(252 197 207 / 80%);
$wzb-pastel-pink-60: rgb(252 197 207 / 60%);
$wzb-pastel-pink-40: rgb(252 197 207 / 40%);
$wzb-pastel-pink-20: rgb(252 197 207 / 20%);
$wzb-pastel-pink-8: rgb(252 197 207 / 8%);

$wzb-pastel-blue: rgb(221, 239, 254);
$wzb-pastel-blue-80: rgb(221 239 254 / 80%);
$wzb-pastel-blue-60: rgb(221 239 254 / 60%);
$wzb-pastel-blue-40: rgb(221 239 254 / 40%);
$wzb-pastel-blue-20: rgb(221 239 254 / 20%);
$wzb-pastel-blue-8: rgb(221 239 254 / 8%);

$wzb-pastel-mauve: rgb(232, 225, 252);
$wzb-pastel-mauve-80: rgb(232 225 252 / 80%);
$wzb-pastel-mauve-60: rgb(232 225 252 / 60%);
$wzb-pastel-mauve-40: rgb(232 225 252 / 40%);
$wzb-pastel-mauve-20: rgb(232 225 252 / 20%);
$wzb-pastel-mauve-8: rgb(232 225 252 / 8%);

$wzb-pastel-orange: rgb(252, 233, 197);
$wzb-pastel-orange-80: rgb(252 233 197 / 80%);
$wzb-pastel-orange-60: rgb(252 233 197 / 60%);
$wzb-pastel-orange-40: rgb(252 233 197 / 40%);
$wzb-pastel-orange-20: rgb(252 233 197 / 20%);
$wzb-pastel-orange-8: rgb(252 233 197 / 8%);

$wzb-pastel-green: rgb(205, 252, 197);
$wzb-pastel-green-80: rgb(205 252 197 / 80%);
$wzb-pastel-green-60: rgb(205 252 197 / 60%);
$wzb-pastel-green-40: rgb(205 252 197 / 40%);
$wzb-pastel-green-20: rgb(205 252 197 / 20%);
$wzb-pastel-green-8: rgb(205 252 197 / 8%);

$wzb-pastel-mint: rgb(211, 246, 240);
$wzb-pastel-mint-80: rgb(211 246 240 / 80%);
$wzb-pastel-mint-60: rgb(211 246 240 / 60%);
$wzb-pastel-mint-40: rgb(211 246 240 / 40%);
$wzb-pastel-mint-20: rgb(211 246 240 / 20%);
$wzb-pastel-mint-8: rgb(211 246 240 / 8%);

$wzb-grey: rgb(117, 116, 145);
$wzb-grey-80: rgb(117 116 145 / 80%);
$wzb-grey-60: rgb(117 116 145 / 60%);
$wzb-grey-40: rgb(117 116 145 / 40%);
$wzb-grey-20: rgb(117 116 145 / 20%);
$wzb-grey-8: rgb(117 116 145 / 8%);

$wzb-light-grey: rgb(230, 235, 245);
$wzb-light-grey-80: rgb(230 235 245 / 80%);
$wzb-light-grey-60: rgb(230 235 245 / 60%);
$wzb-light-grey-40: rgb(230 235 245 / 40%);
$wzb-light-grey-20: rgb(230 235 245 / 20%);
$wzb-light-grey-8: rgb(230 235 245 / 8%);

$wzb-cool-grey: rgb(244, 245, 250);
$wzb-cool-grey-80: rgb(244 245 250 / 80%);
$wzb-cool-grey-60: rgb(244 245 250 / 60%);
$wzb-cool-grey-40: rgb(244 245 250 / 40%);
$wzb-cool-grey-20: rgb(244 245 250 / 20%);
$wzb-cool-grey-8: rgb(244 245 250 / 8%);

$wzb-disabled: rgb(243, 243, 243);
$wzb-disabled-80: rgb(243 243 243 / 80%);
$wzb-disabled-60: rgb(243 243 243 / 60%);
$wzb-disabled-40: rgb(243 243 243 / 40%);
$wzb-disabled-20: rgb(243 243 243 / 20%);
$wzb-disabled-8: rgb(243 243 243 / 8%);

$wzb-stroke: rgb(241, 241, 245);
$wzb-stroke-80: rgb(241 241 245 / 80%);
$wzb-stroke-60: rgb(241 241 245 / 60%);
$wzb-stroke-40: rgb(241 241 245 / 40%);
$wzb-stroke-20: rgb(241 241 245 / 20%);
$wzb-stroke-8: rgb(241 241 245 / 8%);

$wzb-stroke-2: rgb(213, 218, 235);
$wzb-stroke-2-80: rgb(213 218 235 / 80%);
$wzb-stroke-2-60: rgb(213 218 235 / 60%);
$wzb-stroke-2-40: rgb(213 218 235 / 40%);
$wzb-stroke-2-20: rgb(213 218 235 / 20%);
$wzb-stroke-2-8: rgb(213 218 235 / 8%);

$wzb-stroke-3: rgb(222, 218, 255);
$wzb-stroke-3-80: rgb(222 218 255 / 80%);
$wzb-stroke-3-60: rgb(222 218 255 / 60%);
$wzb-stroke-3-40: rgb(222 218 255 / 40%);
$wzb-stroke-3-20: rgb(222 218 255 / 20%);
$wzb-stroke-3-8: rgb(222 218 255 / 8%);

$wzb-background: rgb(244, 243, 242);
$wzb-background-80: rgb(244 243 242 / 80%);
$wzb-background-60: rgb(244 243 242 / 60%);
$wzb-background-40: rgb(244 243 242 / 40%);
$wzb-background-20: rgb(244 243 242 / 20%);
$wzb-background-8: rgb(244 243 242 / 8%);

$wzb-cool-black: rgb(28, 28, 40);
$wzb-cool-black-80: rgb(28 28 40 / 80%);
$wzb-cool-black-60: rgb(28 28 40 / 60%);
$wzb-cool-black-40: rgb(28 28 40 / 40%);
$wzb-cool-black-20: rgb(28 28 40 / 20%);
$wzb-cool-black-8: rgb(28 28 40 / 8%);

$wzb-alert: rgb(252, 90, 90);
$wzb-alert-80: rgb(252 90 90 / 80%);
$wzb-alert-60: rgb(252 90 90 / 60%);
$wzb-alert-40: rgb(252 90 90 / 40%);
$wzb-alert-20: rgb(252 90 90 / 20%);
$wzb-alert-8: rgb(252 90 90 / 8%);

$wzb-hint: rgb(80, 181, 255);
$wzb-hint-80: rgb(80 181 255 / 80%);
$wzb-hint-60: rgb(80 181 255 / 60%);
$wzb-hint-40: rgb(80 181 255 / 40%);
$wzb-hint-20: rgb(80 181 255 / 20%);
$wzb-hint-8: rgb(80 181 255 / 8%);

$wzb-warning: rgb(245, 166, 35);
$wzb-warning-80: rgb(245 166 35 / 80%);
$wzb-warning-60: rgb(245 166 35 / 60%);
$wzb-warning-40: rgb(245 166 35 / 40%);
$wzb-warning-20: rgb(245 166 35 / 20%);
$wzb-warning-8: rgb(245 166 35 / 8%);

$wzb-success: rgb(33, 211, 162);
$wzb-success-80: rgb(33 211 162 / 80%);
$wzb-success-60: rgb(33 211 162 / 60%);
$wzb-success-40: rgb(33 211 162 / 40%);
$wzb-success-20: rgb(33 211 162 / 20%);
$wzb-success-8: rgb(33 211 162 / 8%);
