.loading {
  position: relative;
  background-color: whitesmoke;
  overflow: hidden;

  &::after {
    content: '';
    display: block;
    position: absolute;
    width: 100%;
    height: 100%;
    animation: 2s loading linear 0.5s infinite;
    background: linear-gradient(90deg, transparent, #ededed, transparent);
    inset: 0;
    transform: translateX(-100%);
    z-index: 1;
  }
}

@keyframes loading {
  0% {
    transform: translateX(-100%);
  }
  60% {
    transform: translateX(100%);
  }
  100% {
    transform: translateX(100%);
  }
}
