// stylelint-disable selector-max-specificity, selector-no-qualifying-type, selector-type-no-unknown, no-descending-specificity, selector-max-type
ckeditor.wzb-ckeditor,
.wzb-ckeditor-container {
  --ck-ui-component-min-height: 1.5rem;
  --ck-icon-size: 1rem;
  --ck-dropdown-arrow-size: 0.75rem;
  --wzb-ckeditor-border-color: var(--wzb-bo-light-grey);
}

ckeditor.wzb-ckeditor {
  ul {
    list-style: inside;
    padding: inherit;
    margin: inherit;
  }

  li {
    list-style: inherit;
    margin: 0.25rem 0;
  }

  h2,
  h3,
  h4 {
    margin: 2rem 0 1rem;

    &:first-child {
      margin-top: 1rem;
    }
  }

  h2 {
    font-size: 1.5rem;
  }

  h3 {
    font-size: 1.3125rem;
  }

  h4 {
    font-size: 1.125rem;
  }

  p {
    margin-bottom: 0.25rem;
  }

  a,
  a:hover,
  a:focus {
    text-decoration: underline;
  }

  .table {
    margin: 0;
  }

  .ck {
    &.ck-content {
      background-color: white;
      transition:
        background-color 300ms,
        color 300ms;
      font-size: 0.8125rem;

      &.ck-editor__editable_inline {
        box-sizing: border-box;
        min-height: 18.75rem;
        border-radius: 1.25rem;
        border: 1px solid var(--wzb-ckeditor-border-color);
        padding: 1rem 2rem;
        margin-top: 0.5rem;
        max-width: calc(100vw - 2.5rem);
      }

      &[contenteditable='false'] {
        background-color: var(--wzb-bo-disabled);
        color: var(--wzb-bo-text-disable);
        pointer-events: none;

        &:hover {
          cursor: not-allowed;
        }
      }

      &.ck-focused {
        border: 1px solid var(--wzb-bo-primary);
        box-shadow: none;
        outline: var(--wzb-ckeditor-border-color) auto 0.0625rem;
        outline-offset: 0.3125rem;
      }
    }
  }

  &--error {
    .ck.ck-content.ck-editor__editable_inline {
      border: 1px solid var(--wzb-bo-invalid-red);
    }
  }
}

.wzb-ckeditor-container {
  &__toolbar {
    .ck-toolbar {
      border-radius: 1.25rem !important;
      border: 1px solid var(--wzb-ckeditor-border-color) !important;
      padding: 0 2rem !important;
      max-width: 100% !important;
      min-height: 3rem !important;
      position: sticky !important;
      top: calc(var(--header-row) + var(--header-gap) + var(--sticky-header-row)) !important;
      z-index: 1 !important;
    }

    .ck-toolbar_vertical {
      border-radius: 0 !important;
      padding: 0 !important;
    }
  }

  ul {
    li {
      list-style: disc;
    }
  }
}

.ck-powered-by-balloon {
  display: none !important;
}
