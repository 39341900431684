@use '@angular/material' as mat;
@use 'material/variables' as variables;

.mat-mdc-list-option {
  min-height: 3rem;
  height: unset !important;

  .mat-ripple {
    display: none;
  }

  &.cdk-drag-preview {
    background-color: white;

    .mdc-list-item-content {
      display: flex;
      flex-direction: row-reverse;
      justify-content: space-between;
      align-items: center;
      padding: 0 1rem;
      height: 100%;
    }
  }

  &.cdk-drag-placeholder {
    opacity: 0.25;
  }

  .cdk-drop-list-dragging &.cdk-drag {
    transition: transform 100ms linear;
  }

  &.cdk-drag-animating {
    transition: transform 75ms linear;
  }
}

.mat-mdc-list-option.isDisabled {
  /* stylelint-disable-next-line selector-type-no-unknown */
  .mdc-checkbox {
    display: none;
  }
}

.mat-mdc-optgroup {
  --mat-optgroup-label-text-size: 0.875rem;

  line-height: 140%;
  padding-left: 0.5rem;

  &-label {
    all: unset !important;
    padding: 0.5rem;
  }
}
