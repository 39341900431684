// stylelint-disable selector-max-specificity, selector-no-qualifying-type, selector-type-no-unknown, no-descending-specificity, selector-max-type, max-nesting-depth
@use '@wizbii-components/bo-styles/src/colors/bo-colors.scss' as colors;

:is(app-nav-tabs, mat-tab-group[multipleSideMenu]) {
  --mat-tab-header-active-label-text-color: var(--wzb-bo-purple);
  --mdc-tab-indicator-active-indicator-color: var(--wzb-bo-purple);
  --mat-tab-header-active-focus-label-text-color: var(--wzb-bo-purple);
  --mat-tab-header-active-hover-label-text-color: var(--wzb-bo-purple);
  --mat-tab-header-active-focus-indicator-color: var(--wzb-bo-purple);
  --mat-tab-header-active-hover-indicator-color: var(--wzb-bo-purple);
  --mat-tab-header-inactive-ripple-color: var(--wzb-bo-purple);
  --mat-tab-header-inactive-label-text-color: var(--wzb-bo-text-grey);

  .mat-mdc-tab-header {
    .mat-mdc-tab-labels {
      gap: 1.5rem;

      .mat-mdc-tab {
        flex-grow: unset;
      }

      .mdc-tab {
        padding: 0;
        min-width: 0;
        height: auto;

        .mdc-tab__content {
          margin-bottom: 0.375rem;
          margin-top: 0.25rem;
        }
      }
    }

    .mdc-tab__text-label {
      line-height: 1.3;
      font-size: 0.8125rem;
      font-weight: 500;
      font-style: normal;
      letter-spacing: normal;
      padding: 0;
    }

    .mat-mdc-tab-label-container {
      padding-bottom: 0.125rem;
      flex-grow: 0;
      width: fit-content;
      position: relative;

      &::after {
        content: '';
        position: absolute;
        bottom: 0;
        left: 0;
        right: 0;
        background-color: var(--wzb-bo-border-color);
        height: 0.125rem;
        border-radius: 2px;
      }
    }
  }
}
