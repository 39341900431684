@use 'material/variables' as variables;
@use '@wizbii-components/bo-styles/src/material/modules/bo-navmenu' as navMenu;
@use '@angular/material' as mat;
@use 'bo-table';
@use 'bo-side-menu';
@use 'wzb-notifications';
@use 'wzb-bo-table';
@use 'file-picker';

$primary-contrast: mat.m2-get-color-from-palette(variables.$primary, 'A500');

@include navMenu.wzbBoNavMenu(
  mat.m2-get-color-from-palette(variables.$primary),
  $primary-contrast,
  #3b3989,
  #fffff,
  #2d2e45,
  #fffff
);
