:root {
  .filepicker-contrast {
    .filepicker {
      background-color: #222333;
    }
  }

  .filepicker-error {
    .label {
      transition: color 300ms ease-in-out;
      color: var(--wzb-bo-invalid-red);
    }
    .filepicker {
      transition: border-color 300ms ease-in-out;
      border-color: var(--wzb-bo-invalid-red) !important;
    }
  }

  .preview-with-no-max-width {
    .file-upload__preview__image {
      max-width: 100% !important;
    }
  }
}
