/* stylelint-disable selector-type-no-unknown, selector-max-type */
wzb-bo-menu mat-accordion .mat-expansion-panel {
  box-shadow: none !important;
}

wzb-bo-menu mat-accordion .mat-expansion-panel-body {
  padding: 0 1rem !important;
  .item {
    &:last-child {
      margin-bottom: 1rem !important;
    }
  }
}

wzb-bo-navigation-sidebar-menu .navmenu__links {
  width: auto !important;
}
