@use '@angular/material' as mat;
@use '@angular/cdk';
@use '@wizbii-components/styles/src/utils/visually-hidden' as visuallyHidden;
@use '@wizbii-components/styles/src/buttons/buttons' as buttons;
@use './override/phone-picker/phone-picker' as phonePicker;
@use './override/mat-chip-list-wrapper' as matChipListWrapper;
@use './override/mat-dialog' as matDialog;
@use './override/mat-form-field' as matFormField;
@use './override/mat-icon' as matIcon;
@use './override/mat-select' as matSelect;
@use './override/mat-tooltip' as matTooltip;
@use './modules/wzb-theming/wzb-toggle' as toggle;
@use './modules/wzb-theming/wzb-checkbox' as checkbox;
@use './modules/wzb-theming/wzb-date' as date;
@use './modules/wzb-theming/wzb-faq' as faq;
@use 'sass:map' as map;

@mixin wzbTheming(
  $primary,
  $accent,
  $warn: $mat-red,
  $font-family: 'Montserrat',
  $background: #f9fbfe,
  $primary-btn: null,
  $accent-btn: null,
  $sanitize: true
) {
  @if $sanitize {
    // Add sanitize
    @import 'sanitize.css/sanitize.css';
    @import 'sanitize.css/forms.css';
    @import 'sanitize.css/assets.css';
    @import 'sanitize.css/typography.css';
    @import 'sanitize.css/reduce-motion.css';
  }

  @include cdk.text-field-autofill;
  @include mat.core;

  $primary-palette: mat.m2-define-palette($primary);
  $accent-palette: mat.m2-define-palette($accent, A200, A100, A400);
  $warn-palette: mat.m2-define-palette($warn);

  $typography: mat.m2-define-typography-config(
    $font-family: $font-family,
  );

  $theme: mat.m2-define-light-theme(
    (
      color: (
        primary: $primary-palette,
        accent: $accent-palette,
        warn: $warn-palette,
      ),
      typography: $typography,
    )
  );
  $theme-primary: mat.m2-get-color-from-palette(map.get($theme, primary), 500);
  $theme-accent: mat.m2-get-color-from-palette(map.get($theme, accent), 500);
  $theme-warn: mat.m2-get-color-from-palette(map.get($theme, warn), 500);

  @include mat.all-component-typographies($typography);
  @include mat.all-component-themes($theme);

  // Theming Wizbii
  :root {
    --primary: #{$theme-primary};
    --accent: #{$theme-accent};
    --warn: #{$theme-warn};
  }

  // Override Material
  @include phonePicker.overridePhonePicker();
  @include checkbox.wzbCheckbox($theme-primary, $theme-accent, $theme-warn);
  @include matChipListWrapper.wzbChipList();
  @include matDialog.wzbDialog();
  @include matFormField.wzbFormField($theme-primary, $theme-accent, $theme-warn);
  @include matSelect.wzbMatSelect($theme-primary);
  @include matTooltip.wzbMatTooltip();
  @include matIcon.wzbMatIcon();

  // Form components
  @include toggle.wzbToggle($theme-primary, $theme-accent, $theme-warn);
  @include date.wzbDate($theme-primary, $theme-accent, $theme-warn);
  @include faq.wzbFaq($theme-primary, $theme-accent, $theme-warn);

  // Utils
  @include visuallyHidden.visually-hidden;
}
