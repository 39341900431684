@mixin wzbCheckbox($primary, $accent, $warn) {
  $checkboxSize: 1.25rem;
  // stylelint-disable selector-no-qualifying-type, selector-max-type
  wzb-checkbox {
    padding-bottom: 1.25rem;
    position: relative;

    label {
      display: flex;
      align-items: flex-start;
      z-index: 0;
      position: relative;
      cursor: pointer;
      padding: 0;
      font-weight: normal;

      &::before {
        content: '';
        flex-shrink: 0;
        margin-right: 0.625rem;
        margin-top: 0.1875rem;
        display: inline-block;
        vertical-align: text-top;
        width: $checkboxSize;
        height: $checkboxSize;
        background-color: #d1e1f5;
        border-radius: 0.375rem;
        transition: background-color 0.15s ease-out, border-color 0.15s ease-out, box-shadow 0.15s ease-out;
      }

      &::after {
        transition: background-color 0.15s ease-out, border-color 0.15s ease-out, box-shadow 0.15s ease-out;
      }
    }

    &[checked='true'] {
      label {
        &::before {
          background-color: $primary;
        }

        &::after {
          content: '';
          position: absolute;
          margin-top: 0.1875rem;
          left: 0.3125rem;
          top: 0.5625rem;
          background-color: white;
          width: 0.125rem;
          height: 0.125rem;
          box-shadow: 0.125rem 0 0 white, 0.25rem 0 0 white, 0.25rem -0.125rem 0 white, 0.25rem -0.25rem 0 white,
            0.25rem -0.375rem 0 white, 0.25rem -0.5rem 0 white;
          transform: rotate(45deg);
        }
      }
    }

    &[disabled='true'] {
      label {
        color: #b3b3bf;

        &::before {
          pointer-events: none;
          background-color: #f2f2f4;
        }
      }
    }

    &[invalid='true'] {
      label {
        color: $warn;
      }
    }

    /* stylelint-disable selector-max-specificity  */
    &[disabled='false'][checked='false']:focus-within,
    &[disabled='false'][checked='false']:hover {
      label {
        &::before {
          background-color: white;
          box-shadow: inset 0 0 0 0.125rem $primary;
        }
      }
    }
    /* stylelint-enable  */

    .wzb-checkbox {
      position: absolute;
      opacity: 0;
      left: 0;
      right: 0;
      top: 0;
      bottom: 0;
      height: 100%;
      width: $checkboxSize;
      cursor: pointer;

      &__error {
        position: absolute;
        right: 0;
        bottom: 0;
        color: $warn;
        font-size: 0.625rem;
        font-weight: 500;
        text-align: center;
      }
    }
  }
}
