// stylelint-disable selector-max-specificity, selector-no-qualifying-type, selector-type-no-unknown, no-descending-specificity, selector-max-type, max-nesting-depth
@use '@wizbii-components/bo-styles/src/colors/bo-colors.scss' as colors;

@mixin wzbMatAccordion() {
  .mat-accordion {
    .mat-expansion-panel {
      box-shadow: none;
      border-radius: unset;

      .mat-expansion-panel-header {
        &:hover {
          background-color: inherit !important;
        }

        .mat-content {
          overflow: visible;
        }

        .mat-expansion-indicator {
          display: none;
        }

        &-title {
          font-size: 0.875rem;
          font-family: Montserrat;
          align-items: center;

          .icon {
            width: 2rem;
            height: 2rem;
            box-shadow: 0 0.25rem 0.5rem 0 rgb(96 97 112 / 16%);
            border-radius: 50%;
            margin-right: 0.625rem;
            display: flex;
            align-items: center;
            justify-content: center;
          }

          .mat-icon {
            transform: scale(0.75);
            color: rgb(144, 144, 154);
          }
        }
      }

      &:first-of-type {
        border-top-right-radius: 0.625rem !important;
        border-top-left-radius: 0.625rem !important;

        .mat-expansion-panel-header {
          margin-top: 0.75rem;
        }
      }

      &:last-of-type {
        border-bottom-right-radius: 0.625rem !important;
        border-bottom-left-radius: 0.625rem !important;

        .mat-expansion-panel-header:not(.mat-expanded) {
          margin-bottom: 0.75rem;
        }
      }

      .mat-expanded {
        .mat-expansion-panel-header {
          &-title {
            color: colors.$wzb-electric;
            .icon {
              background-color: colors.$wzb-electric;
            }

            .mat-icon {
              color: white;
            }
          }
        }
      }

      .item {
        margin: 1rem 0;
        font-size: 0.8125rem;
        font-weight: normal;
        text-decoration: none;
        color: inherit;

        &:hover {
          cursor: pointer;
        }

        &:first-of-type {
          margin-top: 0;
        }

        &:last-of-type {
          margin-bottom: 0;
        }
      }
    }

    .mat-expansion-panel-spacing {
      margin: 0;
    }
  }
}
