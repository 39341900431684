/* stylelint-disable no-descending-specificity */
@use '@angular/material' as mat;
@use 'material/variables' as variables;

/* stylelint-disable selector-no-qualifying-type */
a,
button {
  &.mat-mdc-button-base {
    border-radius: 10px;
    width: max-content;
    letter-spacing: normal;

    &.mat-mdc-unelevated-button {
      &.mat-primary {
        &:not(:disabled, [disabled]) {
          --mdc-filled-button-container-color: var(--wzb-bo-primary);
          --mdc-filled-button-label-text-color: white;
        }
      }

      &.mat-accent {
        &:not(:disabled, [disabled]) {
          --mdc-filled-button-container-color: var(--wzb-bo-accent);
          --mdc-filled-button-label-text-color: white;
        }
      }

      .mat-icon {
        display: flex;
        align-items: center;
        justify-content: center;
      }
    }

    &.mdc-button--outlined {
      height: 2.25rem;
      font-weight: 500;
      padding: 0 0.8rem;

      &:not(:disabled, [disabled]) {
        --mdc-outlined-button-outline-color: var(--wzb-bo-accent);
        --mdc-outlined-button-label-text-color: var(--wzb-bo-accent);
        --mat-outlined-button-state-layer-color: var(--wzb-bo-accent);
        --mat-outlined-button-ripple-color: color-mix(in srgb, var(--wzb-bo-accent) 10%, transparent);

        /* stylelint-disable-next-line selector-max-specificity */
        .mat-icon.mat-accent {
          color: var(--wzb-bo-accent);
        }
      }
    }

    &.mat-mdc-icon-button {
      display: flex;
      align-items: center;
      justify-content: center;
      padding: 0;
      max-height: 2.5rem;
      max-width: 2.5rem;

      .mat-icon {
        width: 1.5rem;
        height: 1.5rem;
        margin: auto;
      }

      .mat-icon[tall-icon] {
        width: 1.125rem;
        height: 1.125rem;
      }
    }

    .mat-icon {
      display: flex !important;
      align-items: center;
      justify-content: center;
    }
  }

  &[outline='grey'] {
    &.mdc-button--outlined {
      &:not(:disabled) {
        --mdc-outlined-button-outline-color: var(--wzb-bo-form-field-border);
        --mdc-outlined-button-label-text-color: var(--wzb-bo-text-black);
        --mat-outlined-button-state-layer-color: var(--wzb-bo-text-black);
        --mat-outlined-button-ripple-color: color-mix(in srgb, var(--wzb-bo-text-black) 10%, transparent);

        /* stylelint-disable-next-line selector-max-specificity */
        .mat-icon.mat-accent {
          color: var(--wzb-bo-text-black) !important;
        }
      }
    }
  }
}
