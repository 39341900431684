@mixin wzbMatSelect($primary, $text-color: #000028, $warn: #ff5c60) {
  .mdc-menu-surface {
    padding: 0 !important;
    box-shadow: 0 0.3125rem 0.875rem rgb(0 0 0/ 15%);
    border-radius: 10px !important;
  }

  .mat-mdc-option.mat-mdc-option-active {
    background-color: #f7faff;
    border-radius: 8px;
    margin: 0.3125rem 1rem 0.3125rem 0.3125rem;

    .mdc-list-item__primary-text {
      color: $text-color !important;
    }
  }
}
