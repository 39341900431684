@use '@wizbii-components/styles/src/breakpoints' as breakpoints;

@mixin wzbToggle($primary, $accent, $warn) {
  wzb-toggle,
  .wzb-toggle {
    display: flex;
    align-items: center;
    justify-content: space-between;
    cursor: pointer;

    &--reverse {
      flex-direction: row-reverse;

      .wzb-toggle-button {
        margin-left: 0 !important;
        margin-right: 0.625rem;
      }
    }

    .wzb-toggle-button {
      position: relative;
      min-width: 3.25rem;
      height: 1.875rem;
      margin-left: 0.625rem;
      border: none;
      border-radius: 1rem;
      background-color: $warn;
      cursor: pointer;

      &::before {
        content: ' ';
        position: absolute;
        top: 0.375rem;
        left: 0.625rem;
        width: 0.25rem;
        height: 1.125rem;
        border-radius: 7px;
        background-color: white;
        transition: all 300ms;
      }

      &--marginless {
        margin-left: 0;
      }

      @media (min-width: breakpoints.$sm) {
        &:not(.wzb-toggle-button--marginless) {
          margin-left: 1.75rem;
        }
      }
    }

    .wzb-toggle-label {
      margin-bottom: 0;
      font-size: inherit;
      cursor: pointer;

      @media (max-width: breakpoints.$sm-max) {
        line-height: 1.75;
      }
    }

    &[checked='true'] .wzb-toggle-button {
      background-color: $primary;

      &::before {
        top: 0.3125rem;
        height: 1.25rem;
        width: 1.25rem;
        left: 1.6875rem;
        border-radius: 50%;
      }
    }

    .wzb-toggle-button[disabled] {
      background-color: #f2f2f4;

      &::before {
        height: 0.25rem;
        width: 0.625rem;
        left: 1.3125rem;
        top: 0.8125rem;
        border-radius: 7px;
        background-color: #d8d8d8;
      }
    }

    &.wzb-toggle--small {
      padding: 0.275rem;

      .wzb-toggle-button {
        min-width: 2.3831rem;
        height: 1.375rem;

        &::before {
          width: 0.1831rem;
          height: 0.825rem;
          top: 0.275rem;
          left: 0.4581rem;
        }

        &[disabled] {
          &:before {
            height: 0.25rem;
            width: 0.625rem;
            left: 0.9063rem;
            top: 0.5938rem;
          }
        }
      }

      &[checked='true'] .wzb-toggle-button {
        &::before {
          left: 1.2813rem;
          top: 0.3125rem;
          height: 0.75rem;
          width: 0.75rem;
        }
      }
    }
  }
}
