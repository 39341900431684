/* stylelint-disable selector-no-qualifying-type */

:root {
  *,
  *::before,
  *::after {
    box-sizing: border-box;
  }

  :where(li, ul, ol) {
    list-style: none;
    padding: 0;
    margin: 0;
  }

  :where(img) {
    display: block;
  }

  :where(button) {
    border: none;
    background: none;
    padding: 0;
    margin: 0;
    cursor: pointer;
  }

  :where(a) {
    &:hover,
    &:focus {
      text-decoration: none;
    }
  }

  :where(fieldset) {
    border: none;
    padding: 0;
    margin: 0;
  }

  :where(legend) {
    color: var(-wzb-bo-title);
    font-size: 1.125rem;
    font-style: normal;
    font-weight: 500;
    line-height: 140%;
  }

  hr {
    border: none;
    width: 100%;
    height: 0.0625rem;
    margin: 0;
    background-color: var(--wzb-bo-form-field-border);
  }

  input[type='number']::-webkit-inner-spin-button,
  input[type='number']::-webkit-outter-spin-button {
    display: none;
    margin: 0;
  }
}
