/* stylelint-disable no-descending-specificity */
/* stylelint-disable selector-max-specificity */
/* stylelint-disable selector-not-notation */
.mat-mdc-checkbox {
  &.mat-primary {
    &.mat-mdc-checkbox-checked {
      .mdc-form-field {
        --mat-checkbox-label-text-color: var(--wzb-bo-primary);
        --mdc-checkbox-selected-checkmark-color: white;
      }
    }

    .mdc-form-field {
      --mat-checkbox-label-text-size: 0.8125rem;
      --mat-checkbox-label-text-line-height: 1.3;

      .mdc-label {
        padding: 0;
        margin-left: -0.1875rem;
      }
    }

    .mdc-checkbox {
      padding: calc((var(--mdc-checkbox-state-layer-size) - 1rem) / 2);

      &__native-control {
        &:enabled:not(:checked):not(:hover):not(:focus) ~ .mdc-checkbox__background {
          background-color: white;
        }
      }

      &__background {
        border-radius: 6px;
        width: 1.25rem;
        height: 1.25rem;
      }

      &__checkmark {
        padding: 0.2188rem;
        stroke-linecap: round;
        stroke-linejoin: round;
        overflow: visible;

        &-path {
          stroke-width: 0.375rem;
        }
      }
    }
  }
}
