@use '@wizbii-components/styles/src/colors/colors' as colors;
@use '@wizbii-components/styles/src/fonts/wzb-font' as fonts;

@mixin wzbMatTooltip() {
  .mat-mdc-tooltip {
    --mdc-plain-tooltip-container-color: white;
    --mdc-plain-tooltip-supporting-text-color: colors.$wzb-black;
    --mdc-plain-tooltip-container-shape: 0.625rem;
    --mdc-plain-tooltip-supporting-text-font: fonts.$wzb-global-font-family;
    --mdc-plain-tooltip-supporting-text-font-size: 0.8125rem;

    box-shadow: 0px 15px 30px rgba(0, 0, 0, 0.15);
    border-radius: var(--mdc-plain-tooltip-container-shape);

    .mdc-tooltip__surface {
      padding: 0.625rem 0.875rem;
    }
  }
}
