@use 'sass:color' as color;
@use '@wizbii-components/styles/src/colors/colors' as colors;

// stylelint-disable selector-max-specificity, selector-no-qualifying-type, selector-type-no-unknown, no-descending-specificity, selector-max-type

@mixin wzbFaq($primary, $accent, $warning) {
  .faq-view,
  .faq {
    &__link {
      color: $primary;
      text-decoration: none;

      &:hover {
        text-decoration: underline;
      }
    }
  }

  .faq-view__actions__btn {
    color: white;
    background-color: $primary;
  }

  .question-faq {
    background-color: white;
    border: 1px solid #f2f2f4;
    border-radius: 1.25rem;

    &__link {
      color: colors.$wzb-blue;
      text-decoration: none;

      &:hover {
        text-decoration: underline;
      }
    }

    &__indicator {
      color: $primary;
      width: 1.875rem;
      height: 1.875rem;
      border-radius: 50%;
      transition: color 0.1s ease-out, background-color 0.1s ease-out,
        border-color 0.1s ease-out;
    }

    :host-context(.hover-on) &:hover {
      .question-faq__indicator {
        background-color: color.scale($color: $primary, $alpha: -80%);
      }
    }

    &[open] .question-faq {
      &__icon {
        display: none;
      }

      &__indicator::before {
        display: block;
        width: 1rem;
        height: 0.125rem;
        background-color: currentcolor;
        border-radius: 0.0625rem;
        content: '';
      }

      &__answer {
        animation: reveal 0.2s ease-out backwards;

        .pen-red {
          padding-left: 0.25rem;
          padding-right: 0.25rem;
          background-image: linear-gradient(
            hsl(0deg 0% 100% / 0%),
            hsl(0deg 0% 100% / 0%) 12%,
            colors.$wzb-blue 0,
            colors.$wzb-blue 70%,
            hsl(0deg 0% 100% / 0%) 0
          );
          background-color: inherit;
        }
      }
    }

    @keyframes reveal {
      from {
        opacity: 0;
      }
    }
  }
}
