@import './variables/colors';
@import './variables/measures';
@import './material/theme';
@import './wizbii-styles/theme';
@import './components/theme';
@import './components/skeleton';
@import './override/ckeditor/ckeditor';
@import './override/text-area-with-preview/text-area-with-preview';
@import './override/reset';

/* stylelint-disable selector-max-type */
body {
  background-color: var(--wzb-bo-background);
  height: 100svh;

  p {
    margin: 0;
  }

  a {
    color: var(--wzb-bo-blue);
    text-decoration: none;
  }
}

svg {
  pointer-events: none;
}
