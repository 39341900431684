@use '../colors/colors' as colors;

.wzb-btn-clean {
  display: inline-flex;
  border: none;
  color: inherit;
  text-align: center;
  align-items: center;
  flex-direction: row;
  text-overflow: ellipsis;
  overflow: hidden;
  cursor: pointer;
  overflow-anchor: none;
  background-color: transparent;
  transition: transform 0.15s ease-out;
  font: inherit;
}

.wzb-btn {
  @extend .wzb-btn-clean;

  text-decoration: none;
  color: white;
  background-color: var(--primary);
  border-radius: 10px;
  padding: 0.75rem 1.25rem;
  box-shadow: 0 6px 15px rgb(0 0 40 / 20%);
  font-weight: 600;
  font-size: 1rem;
  line-height: 1.4;
  justify-content: center;

  &:disabled {
    background-color: colors.$grey-4 !important;
    color: colors.$grey-6 !important;
    box-shadow: none;
    border-color: colors.$grey-4;
  }

  &:not(:disabled):hover {
    box-shadow: 0 14px 25px rgb(0 0 40 /20%);
    transform: scale(1.02);
  }

  &--outline {
    border: 1px solid var(--primary);
    background-color: white;
    color: colors.$wzb-black;
    box-shadow: none;

    &:hover {
      box-shadow: none !important;
    }
  }

  &--icon {
    padding: 0.75rem 0.9375rem;
    gap: 0.75rem;

    &:disabled {
      background-color: transparent;
    }
  }

  &--big {
    padding: 1rem 1.25rem 1.0625rem;
  }

  &--full-width {
    width: 100%;
    justify-content: center;

    &-icon-left {
      width: 100%;
      display: grid;
      grid-template-columns: 24px 1fr;
    }

    &-icon-right {
      width: 100%;
      display: grid;
      grid-template-columns: 1fr 24px;
    }

    &-icon-left-right {
      display: grid;
      width: 100%;
      grid-template-columns: 24px 1fr 24px;
    }
  }

  &--text {
    padding: 0;
    background-color: transparent;
    box-shadow: none;
    border: none;
    color: var(--primary);

    &:hover {
      text-decoration: underline;
      box-shadow: none;
      transform: none;
      cursor: pointer;
    }
  }
}

.btn-clean {
  display: inline-block;
  padding: 0;
  background: transparent;
  border: none;
  color: inherit;
  text-align: center;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  cursor: pointer;
  appearance: none;
}

@mixin custom-wzb-btn($btn-color, $btn-background-color, $btn-box-shadow) {
  @extend .wzb-btn;

  color: $btn-color;
  background-color: $btn-background-color;
  box-shadow: 0 6px 15px $btn-box-shadow;
}

@mixin wzb-btn-clean() {
  @extend .wzb-btn-clean;
}

.wzb-suggestion-btn {
  display: inline-flex;
  box-sizing: border-box;
  justify-content: center;
  align-items: center;
  min-width: 8.5rem;
  padding: 0.75rem 1.25rem;
  background-color: var(--primary);
  border: none;
  border-radius: 0.625rem;
  color: var(--text-color);
  font-family: inherit;
  font-size: 1rem;
  font-weight: normal;
  line-height: 1.65;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  outline-offset: 1px;
  box-shadow: none;
  transition:
    opacity 0.15s ease-out,
    box-shadow 0.15s ease-out,
    background-color 0.15s ease-out,
    color 0.15s ease-out;
  cursor: pointer;
  position: relative;
  opacity: 1;
  text-decoration: none;

  &:hover,
  &:focus {
    text-decoration: none;
    background-color: var(--suggestion-btn-hover, var(--accent));
  }

  &--icon {
    svg-icon {
      font-size: 1rem;
      margin-left: 0.3125rem;
    }
  }

  &:disabled,
  &--disabled {
    color: colors.$grey-6;
    pointer-events: none;
    box-shadow: none;
  }

  &:active {
    box-shadow: 0 0.625rem 1.25rem rgb(0 0 0 / 0%);
  }

  &--selected {
    background-color: var(--accent);
    color: white;
    box-shadow: 0 0.625rem 1.25rem rgb(69 207 255 / 30%);

    &:hover,
    &:focus {
      background-color: var(--accent);
    }

    &:disabled {
      background-color: rgb(var(--accent), 15%);
      color: rgb(var(--accent), 40%);
    }
  }

  &--invalid {
    outline: 1px solid var(--warn);
    color: var(--warn);
    box-shadow: none;
    background-color: transparent;
  }
}
