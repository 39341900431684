/* stylelint-disable selector-max-specificity, no-descending-specificity */

.mat-mdc-form-field.mat-form-field-appearance-fill {
  width: 100%;

  &.dropdown-tags--error {
    .mdc-text-field {
      border-color: var(--mdc-theme-error);
    }
  }

  &:not(.mat-form-field-hide-placeholder, .mat-form-field-invalid, .mat-form-field-disabled)
    .mat-mdc-text-field-wrapper.mdc-text-field--filled {
    border-color: var(--wzb-bo-blue);
  }

  .mat-mdc-input-element {
    font-size: 0.8125rem;
  }

  .mdc-text-field {
    background-color: white;
    border: 1px solid var(--wzb-bo-form-field-border);
    border-radius: 10px;

    .mat-mdc-form-field-focus-overlay {
      display: none;
    }

    .mat-mdc-form-field-infix {
      min-height: unset;
      padding-top: 1.1875rem;
      padding-bottom: 0.3125rem;

      &:has(.fields-manager-select) {
        padding: 0.8rem 0;
      }
    }

    &:hover {
      border-color: var(--wzb-bo-blue);
    }

    .mdc-floating-label {
      top: 1.5625rem;
      font-size: 0.8125rem;

      /* stylelint-disable-next-line selector-type-no-unknown */
      &--float-above mat-label {
        color: var(--wzb-bo-purple);
      }
    }

    &:not(.mdc-text-field--invalid) .mdc-floating-label {
      color: var(--wzb-bo-text-black);
      font-weight: 500;
    }

    .mat-mdc-form-field-icon-prefix {
      display: flex;
      align-items: center;
      height: 3.0625rem;
      align-self: flex-start;
      padding: 0 0.25rem 0 0.375rem;

      .mat-icon {
        color: var(--wzb-bo-text-black);
        width: 0.9rem;
        height: 0.9rem;
        padding: 0.9rem;
      }

      button .mat-icon {
        width: 1rem;
        height: 1rem;
        padding: 0;
        overflow: visible;
      }
    }

    &--invalid,
    &--invalid:hover {
      border-color: var(--mdc-theme-error);

      .mat-mdc-form-field-icon-prefix .mat-icon {
        color: var(--mdc-theme-error);
      }
    }

    textarea {
      margin-top: 0.25rem;
    }
  }

  &.mat-form-field-disabled .mdc-text-field {
    background-color: #f8f8f8;

    /* stylelint-disable-next-line selector-type-no-unknown */
    mat-label,
    .mat-mdc-input-element {
      color: #666;
    }
  }

  .mdc-text-field,
  .mdc-text-field:hover {
    .mdc-line-ripple {
      &::before,
      &::after {
        border: none;
      }
    }
  }

  .mat-mdc-form-field-hint-wrapper,
  .mat-mdc-form-field-error-wrapper {
    padding: 0 !important;
  }

  .mat-mdc-form-field-hint-spacer {
    flex: unset;
  }
}

legend {
  margin-bottom: 1rem;
}

.mat-mdc-form-field-error {
  font-size: 0.625rem;
}
