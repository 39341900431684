@mixin wzbDialog() {
  :root {
    --mdc-dialog-container-shape: 20px;
    --mdc-dialog-container-elevation: 0 0.625rem 1.875rem 0.625rem rgb(0 0 40, 5%);
  }

  .mdc-dialog {
    &__title {
      padding: 1.875rem 1.25rem 0 !important;

      &::before {
        content: unset !important;
      }
    }

    &__actions {
      padding: 0 0 1.875rem 0 !important;
      flex-direction: column;
      gap: 1.25rem;
    }
  }

  .mat-mdc-dialog-actions {
    justify-content: center !important;
  }

  .wzb-backdrop {
    background-color: rgb(0 0 40 / 54%);
  }
}
