@use '@wizbii-components/styles/src/colors/colors' as colors;

@mixin wzbDate($primary, $accent, $warning) {
  .wzb-date__error {
    position: absolute;
    bottom: 0;
    left: 0;
    font-size: 0.75rem;
    font-weight: 600;
    line-height: 1.5;
    color: colors.$wzb-red;
  }

  .wzb-date__input {
    .mat-form-field-wrapper {
      padding-bottom: 0 !important;
    }
  }
}
