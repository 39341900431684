@use '@wizbii-components/styles/src/colors/colors' as colors;

@mixin wzbFormField($theme-primary, $theme-accent, $theme-warn) {
  mat-form-field.mat-form-field-appearance-outline {
    .mdc-text-field {
      .mdc-notched-outline {
        &__leading {
          border-radius: 10px 0 0 10px;
        }

        &__trailing {
          border-radius: 0 10px 10px 0;
        }
      }

      .mdc-notched-outline__notch {
        border-right: none;
      }

      .mdc-floating-label {
        color: colors.$wzb-black;

        &--float-above {
          color: $theme-primary;
          font-weight: 600;
        }
      }

      &--outlined:not(.mdc-text-field--disabled):not(.mdc-text-field--focused):not(.mdc-text-field--invalid) {
        .mdc-notched-outline .mdc-notched-outline {
          &__leading,
          &__notch,
          &__trailing {
            border-color: colors.$grey-4;
          }
        }

        &:hover .mdc-notched-outline .mdc-notched-outline {
          &__leading,
          &__notch,
          &__trailing {
            border-color: $theme-primary;
          }
        }
      }

      &--invalid:not(.mdc-text-field--disabled) {
        .mdc-floating-label--float-above {
          color: $theme-warn;
        }
      }

      &--disabled {
        .mdc-floating-label {
          color: colors.$grey-6;
        }

        mat-icon {
          color: colors.$grey-5;
        }
      }
    }

    .mat-mdc-form-field-subscript-wrapper {
      .mat-mdc-form-field-bottom-align::before {
        height: 0;
      }

      .mat-mdc-form-field-error-wrapper,
      .mat-mdc-form-field-hint-wrapper {
        padding: 0;

        .mat-mdc-form-field-error,
        .mat-mdc-form-field-hint {
          font-size: 0.75rem;
          margin-top: 0.25rem;
          line-height: 1.5;
        }
      }

      .mat-mdc-form-field-error-wrapper .mat-mdc-form-field-error {
        font-weight: 600;
        color: $theme-warn;
      }

      .mat-mdc-form-field-hint .mat-mdc-form-field-hint {
        color: colors.$wzb-black;
      }
    }

    .mat-mdc-form-field-text-prefix {
      top: 0 !important;
      padding-right: 1rem;
      font-weight: 500;
      font-size: 1.25rem;
      opacity: 0.6;
      transition:
        color 0.1s,
        opacity 0.1s;
    }
  }
}
