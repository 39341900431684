body {
  font-family: Montserrat, sans-serif;

  --mdc-typography-body1-font-size: 0.8125rem;
  --mdc-typography-caption-font-size: 0.625rem;
  --mat-select-trigger-text-size: 0.8125rem;
  --mdc-theme-error: var(--wzb-bo-invalid-red);
}

h1,
h2,
h3,
h4,
h5 {
  font-weight: 500;
  line-height: 1.4;
  color: var(--wzb-bo-text-black);
  margin: 0;
}

h1 {
  font-size: 1.5rem;
  color: var(--wzb-bo-primary);
}

h2 {
  font-size: 1.375rem;
}

h3 {
  font-size: 1.125rem;
}

h4 {
  font-size: 0.9375rem;
}

h5 {
  font-size: 0.8125rem;
}
