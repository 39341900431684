$wzb-black: rgb(0 0 40);

$wzb-purple: rgb(134 131 255);
$wzb-purple-40: rgb(134 131 255 / 40%);
$wzb-purple-15: rgb(134 131 255 /15%);

$wzb-light-blue: rgb(0 209 255);
$wzb-light-blue-40: rgb(0 209 255 / 40%);
$wzb-light-blue-15: rgb(0 209 255 /15%);

$wzb-light-green: rgb(7 255 210);
$wzb-light-green-40: rgb(7 255 210 / 40%);
$wzb-light-green-15: rgb(7 255 210 /15%);

$wzb-light-yellow: rgb(251 237 33);
$wzb-light-yellow-40: rgb(251 237 33 / 40%);
$wzb-light-yellow-15: rgb(251 237 33 / 15%);

$wzb-dark-blue: rgb(10 36 86);
$wzb-dark-blue-40: rgb(10 36 86 / 40%);
$wzb-dark-blue-15: rgb(10 36 86 / 15%);

$wzb-pink: rgb(242 159 255);
$wzb-pink-40: rgb(242 159 255 / 40%);
$wzb-pink-15: rgb(242 159 255 / 15%);

$wzb-red: rgb(255 84 89);
$wzb-red-40: rgb(255 84 89 / 40%);
$wzb-red-15: rgb(255 84 89 / 15%);

$wzb-blue: rgb(87 80 251);
$wzb-blue-40: rgb(87 80 251 / 40%);
$wzb-blue-15: rgb(87 80 251 / 15%);

$wzb-green: rgb(0 238 152);
$wzb-green-40: rgb(0 238 152 / 40%);
$wzb-green-15: rgb(0 238 152 / 15%);

$wzb-yellow: rgb(248 198 11);
$wzb-yellow-40: rgb(248 198 11 / 40%);
$wzb-yellow-15: rgb(248 198 11 / 15%);

$grey-1: rgb(247 250 255);
$grey-2: rgb(242 242 244);
$grey-3: rgb(216 216 216);
$grey-4: rgb(209 225 245);
$grey-5: rgb(183 194 209);
$grey-6: rgb(179 179 191);
$grey-7: rgb(162 187 218);

$colors: (
  'purple': $wzb-purple,
  'blue': $wzb-light-blue,
  'blue-dark': $wzb-dark-blue,
  'yellow': $wzb-yellow,
  'red': $wzb-red,
  'green': $wzb-green,
  'pink': $wzb-pink,
  'black': $wzb-black,
);
