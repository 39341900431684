.mat-mdc-autocomplete-panel {
  border-radius: 20px;

  $box-shadow-color: rgba(96 97 112 / 16%);

  &:not([class*='mat-elevation-z']) {
    box-shadow: 0 0.5rem 1rem $box-shadow-color;
  }

  .mat-mdc-option {
    line-height: 2.25;
    height: 2.5rem;
    min-height: unset;
    font-size: 0.875rem;
    padding: 0 0.5rem;

    .mdc-list-item__primary-text {
      border-radius: 8px;
      width: 100%;
      padding: 0 0.5rem;

      &:hover {
        background-color: #e1e0f9;
      }
    }

    &.dropdown-tag-option {
      padding: 0;

      .mdc-list-item__primary-text {
        height: 100%;
        padding: 0;
      }
    }

    &:hover {
      background: transparent !important;
      color: var(--wzb-bo-purple);
    }

    .mat-pseudo-checkbox {
      display: none;
    }
  }
}
