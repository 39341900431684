@mixin overridePhonePicker() {
  //override phone picker autocomplete panel
  .wzb-phone-picker-autocomplete-panel {
    border-radius: 0 0 10px 10px !important;
    box-shadow: 0 9px 14px rgb(0 0 0 / 15%) !important;
  }

  .phone-picker {
    &__input {
      .mat-form-field-prefix {
        top: 0 !important;
      }
    }

    &__autocomplete {
      .mat-form-field-wrapper {
        padding: 0 !important;
        margin: 0 !important;
        background-color: #f7faff !important;
        border-radius: 10px !important;
        box-shadow: 0 -9px 14px rgb(0 0 0 / 15%) !important;
      }

      .mat-form-field-flex {
        border: 5px solid white !important;
        border-radius: 10px !important;
      }

      .mat-form-field-infix {
        border: 0 !important;
        padding: 1em 0 1em 0 !important;
      }

      .mat-form-field-outline-start,
      .mat-form-field-outline-gap,
      .mat-form-field-outline-end {
        border: none !important;
      }

      &--open {
        .mat-form-field-wrapper {
          border-radius: 10px 10px 0 0 !important;
        }

        .mat-form-field-flex {
          border-radius: 10px 10px 0 0 !important;
        }
      }
    }
  }
}
