/* stylelint-disable selector-max-type, selector-no-qualifying-type */

@mixin form-with-sections {
  header {
    border-radius: var(--wzb-bo-border-radius) var(--wzb-bo-border-radius) 0 0;
    background-color: white;
    padding: 1.5rem;
    display: flex;
    align-items: center;
    gap: 1rem;
    flex-wrap: wrap;
    position: sticky;
    top: calc(var(--header-row));
    z-index: 2;
    border-bottom: 1px solid var(--wzb-bo-light-grey);

    > button[type='submit'] {
      margin-left: auto;
    }
  }

  header.no-top-space {
    top: 0;
  }

  > ul {
    display: grid;
    gap: 1.25rem;
    > :first-child {
      border-radius: 0 0 var(--wzb-bo-border-radius) var(--wzb-bo-border-radius);
    }

    > li {
      border-radius: var(--wzb-bo-border-radius);
      background-color: white;
      padding: 1.5rem;
    }

    @include form-section('fieldset');
  }
}

@mixin form-section($section-selector) {
  #{$section-selector} {
    display: grid;
    grid-template-columns: repeat(2, minmax(0, 1fr));
    gap: 1rem;

    > :not(.half-width-field) {
      grid-column: 1 / -1;
    }
  }
}
