/* stylelint-disable unit-disallowed-list */
// stylelint-disable selector-max-specificity, no-descending-specificity

:root {
  --mat-expansion-background-color: #f5faff;
  --mat-expansion-header-hover-state-layer-color: var(--mat-expansion-background-color);
  --mat-expansion-header-focus-state-layer-color: var(--mat-expansion-background-color);
  --mat-expansion-header-text-color: var(--wzb-bo-text-black);
  --mat-expansion-header-expanded-state-height: 3rem;
}

/* stylelint-disable-next-line selector-not-notation */
.mat-accordion:not(.wzb-bo-menu):not([multiplesidemenu]):not(.block-accordion) {
  .mat-expansion-panel {
    box-shadow:
      0 3px 1px -2px rgb(0 0 0 / 20%),
      0 2px 2px 0 rgb(0 0 0 / 14%),
      0 1px 5px 0 rgb(0 0 0 / 12%) !important;
  }
}

.mat-accordion.block-accordion .mat-expansion-panel {
  box-shadow: unset !important;

  .mat-expansion-panel-header {
    border: 1px solid var(--wzb-bo-light-grey);
    padding: 0 1rem;

    &:hover {
      background-color: var(--mat-expansion-header-hover-state-layer-color) !important;
    }

    &.mat-expanded {
      background-color: var(--mat-expansion-background-color);
    }

    .mat-expansion-indicator {
      display: block;
    }
  }

  &:last-of-type .mat-expansion-panel-header:not(.mat-expanded) {
    margin-bottom: 0;
  }

  &:first-of-type .mat-expansion-panel-header {
    margin-top: 0;
  }

  .mat-expansion-panel-body {
    padding: 1rem 0;
  }
}
