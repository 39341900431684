@use '@wizbii-components/angular-styles/src/theming' as theming;
@use './modules/bo-navmenu' as boNavMenu;

@use '@angular/material' as mat;
@use '@angular/cdk';
@use 'sass:map' as map;

@use './override/mat-select' as select;
@use './override/mat-accordion' as accordion;
@use './override/mat-table' as table;
@use './override/mat-slide-toggle' as slideToggle;

// stylelint-disable value-keyword-case

@mixin boTheming(
  $primary,
  $accent,
  $warn: $mat-red,
  $font-family: 'Montserrat',
  $background: #f9fbfe,
  $sanitize: true
) {
  @include theming.wzbTheming($primary, $accent, $warn, $font-family, $background, $sanitize: $sanitize);

  $primary-palette: mat.m2-define-palette($primary);
  $accent-palette: mat.m2-define-palette($accent, A200, A100, A400);
  $warn-palette: mat.m2-define-palette($warn);

  $typography: mat.m2-define-typography-config(
    $font-family: $font-family,
  );

  $theme: mat.m2-define-light-theme(
    (
      color: (
        primary: $primary-palette,
        accent: $accent-palette,
        warn: $warn-palette,
      ),
      typography: $typography,
    )
  );

  $theme-primary: mat.m2-get-color-from-palette(map.get($theme, primary), 500);
  $theme-primary-contrast: mat.m2-get-color-from-palette(map.get($theme, primary), '500-contrast');
  $theme-accent: mat.m2-get-color-from-palette(map.get($theme, accent), 500);
  $theme-accent-contrast: mat.m2-get-color-from-palette(map.get($theme, accent), '500-contrast');
  $theme-warn: mat.m2-get-color-from-palette(map.get($theme, warn), 500);

  // Select components
  @include select.wzbSelect($theme-primary, $theme-accent, $theme-warn);
  @include accordion.wzbMatAccordion();
  @include table.wzbBoTable($theme-primary, $theme-accent);
  @include slideToggle.wzbSlideToggle();

  @include boNavMenu.wzbBoNavMenu(
    $theme-primary,
    $theme-primary-contrast,
    $theme-accent,
    $theme-accent-contrast,
    $theme-accent,
    $theme-accent-contrast
  );
}
