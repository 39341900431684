@use '@wizbii-components/styles/src/breakpoints' as breakpoints;
@use '@wizbii-components/styles/src/colors/colors' as colors;

@mixin wzbSelect($primary, $accent, $warn) {
  .wzb-bo-select-panel {
    transform: scaleY(1) translateY(24px) !important;
    background-color: white !important;
    box-shadow: 0 15px 30px rgb(0 0 0 / 15%) !important;
    border-radius: 10px !important;
    min-width: 229px !important;

    .mat-option {
      transition: background-color ease-in-out 200ms;

      .mat-option-text {
        font-weight: 400;
        font-size: 1rem;
      }

      .mat-pseudo-checkbox.mat-option-pseudo-checkbox {
        background-color: colors.$grey-4;
        border-radius: 6px;
        border-color: colors.$grey-4;
        width: 20px;
        height: 20px;

        &.mat-pseudo-checkbox-checked {
          border-color: colors.$wzb-light-blue;
          background-color: colors.$wzb-light-blue;

          &::after {
            top: 3.6px;
            left: 2.2px;
            width: 9px;
            height: 4px;
          }
        }
      }

      &.mat-selected:not(.mat-option-disabled) {
        color: black;
        background-color: white;
      }

      &:not(.mat-option-disabled) {
        &.mat-active,
        &:hover {
          background-color: colors.$grey-1;
        }
      }
    }
  }

  wzb-bo-select {
    mat-form-field.wzb-bo-select {
      .mat-form-field-wrapper {
        padding: 0;
      }

      .mat-form-field-infix {
        border: none;
      }

      .mat-form-field-underline {
        display: none;
      }

      &.mat-form-field-disabled {
        opacity: 0.5;
        cursor: not-allowed;

        & * {
          cursor: not-allowed;
        }
      }

      .mat-form-field-label {
        &.mat-empty.mat-form-field-empty {
          color: black;
        }
      }

      .mat-select-value {
        width: fit-content;
        margin-right: 0.4375rem;
      }

      .mat-select-arrow {
        position: relative;
        width: 0.6563rem;
        height: 0.3906rem;
        display: inline-block;
        border: none;
        margin: 0;
        transition: transform ease-in-out 200ms;

        &:before,
        &:after {
          content: '';
          position: absolute;
          background-color: black;
          border-radius: 1rem;
          display: block;
          top: -5%;
          height: 125%;
          width: 20%;
          transform: rotate(45deg);
          transition: background-color ease-in-out 200ms;

          &:hover {
            background-color: black;
          }
        }

        &:before {
          left: 60%;
        }

        &:after {
          right: 60%;
          transform: rotate(-45deg);
        }
      }

      &.mat-focused.mat-primary {
        .mat-select-arrow {
          transform: rotate(-180deg);
        }
      }
    }
  }
}
