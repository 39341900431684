/* stylelint-disable selector-type-no-unknown */
.mat-mdc-chip.mat-mdc-standard-chip {
  background-color: var(--chip-row-disabled-background-color, var(--wzb-bo-blue)) !important;
  color: white;
  border-radius: 5px;
  height: fit-content;
  padding: 0.3125rem 0;

  .mdc-evolution-chip__cell--trailing {
    opacity: 1 !important;
  }

  &:not(.mdc-evolution-chip--disabled) {
    .mdc-evolution-chip__icon--trailing,
    .mdc-evolution-chip__text-label {
      color: white;
    }
  }

  .mat-icon {
    height: auto;
  }
}

app-dropdown-tags .mat-mdc-text-field-wrapper.mdc-text-field {
  padding-right: 1rem;
}

.mat-mdc-standard-chip.mdc-evolution-chip--disabled {
  --chip-row-disabled-background-color: #6d7079;
  --mdc-chip-disabled-label-text-color: white;
  --mdc-chip-with-trailing-icon-disabled-trailing-icon-color: white;

  opacity: 1 !important;

  .mdc-evolution-chip__text-label mat-mdc-chip-action-label {
    color: white;
  }
}
