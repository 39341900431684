@mixin wzbBoTable($primary, $accent) {
  .mat-mdc-cell {
    font-size: 0.813rem;
    padding: 0.4rem 1rem 0.4rem 0;
    transition: padding cubic-bezier(0.4, 0, 0.2, 1) 225ms;
    border-radius: 10px;
    border: none;
    box-shadow: none;
    border-color: transparent;

    &:last-of-type {
      padding-right: 1.4rem;
    }
  }

  .mat-mdc-table {
    background-color: unset;

    .mat-mdc-header-row {
      border-radius: 10px;
      background-color: rgba($primary, 0.15);
      min-height: 2.4rem !important;
      margin-bottom: 1rem;
      border: unset;

      .mat-mdc-header-cell {
        font-weight: normal;
        color: $primary;
        font-size: 0.825rem;
        border-radius: 10px;
        background-color: transparent;
        border: none;
        box-shadow: none;
        border-color: transparent;
      }
    }

    .mat-mdc-row {
      border-radius: 10px;
      border: 1px solid #e4e4eb;
      background-color: white;
      margin-bottom: 0.5rem;
    }
  }
}
