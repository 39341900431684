@use '@wizbii-components/styles/src/breakpoints' as breakpoints;
@use '@wizbii-components/styles/src/colors/colors' as colors;

@mixin wzbSlideToggle() {
  .mat-mdc-slide-toggle {
    /* colours configuration */
    --selected-handle-color: white;
    --selected-track-color: var(--primary);
    --unselected-handle-color: #92929d;
    --unselected-track-color: white;
    --disabled-track-color: #f1f9ff;
    --disabled-outline-color: #dbe5ee;
    --handle-size: 0.625rem;
    --selected-handle-horizontal-margin: 0 calc(var(--handle-size) / -2);
    --unselected-handle-horizontal-margin: 0 calc(var(--handle-size) / 2);

    /* Component configuration */
    --mdc-switch-track-height: 1.5rem;
    --mdc-switch-track-shape: 1rem;
    --mdc-switch-track-width: 2.4375rem;
    --mat-switch-track-outline-color: #dcd9e3;
    --mdc-switch-handle-width: var(--handle-size);
    --mat-switch-pressed-handle-size: var(--handle-size) !important;
    --mat-switch-selected-handle-size: var(--handle-size) !important;
    --mat-switch-unselected-handle-size: var(--handle-size) !important;

    --mat-switch-selected-handle-horizontal-margin: var(--selected-handle-horizontal-margin) !important;
    --mat-switch-selected-pressed-handle-horizontal-margin: var(--selected-handle-horizontal-margin) !important;
    --mat-switch-unselected-handle-horizontal-margin: var(--unselected-handle-horizontal-margin) !important;
    --mat-switch-unselected-pressed-handle-horizontal-margin: var(--unselected-handle-horizontal-margin) !important;
    --mdc-switch-selected-handle-color: var(--selected-handle-color) !important;
    --mdc-switch-selected-focus-handle-color: var(--selected-handle-color) !important;
    --mdc-switch-selected-hover-handle-color: var(--selected-handle-color) !important;
    --mdc-switch-selected-pressed-handle-color: var(--selected-handle-color) !important;
    --mdc-switch-selected-track-color: var(--selected-track-color) !important;
    --mdc-switch-selected-focus-track-color: var(--selected-track-color) !important;
    --mdc-switch-selected-hover-track-color: var(--selected-track-color) !important;
    --mdc-switch-selected-pressed-track-color: var(--selected-track-color) !important;

    --mdc-switch-unselected-icon-size: 0;
    --mat-switch-unselected-with-icon-handle-horizontal-margin: auto 0.3125rem;
    --mat-switch-unselected-pressed-handle-horizontal-margin: auto 0.3125rem;
    --mdc-switch-unselected-handle-color: var(--unselected-handle-color);
    --mdc-switch-unselected-focus-handle-color: var(--unselected-handle-color);
    --mdc-switch-unselected-hover-handle-color: var(--unselected-handle-color);
    --mdc-switch-unselected-pressed-handle-color: var(--unselected-handle-color);
    --mdc-switch-unselected-track-color: var(--unselected-track-color);
    --mdc-switch-unselected-focus-track-color: var(--unselected-track-color);
    --mdc-switch-unselected-hover-track-color: var(--unselected-track-color);
    --mdc-switch-unselected-pressed-track-color: var(--unselected-track-color);
    --mat-switch-track-outline-color: var(--unselected-handle-color) !important;

    --mdc-switch-disabled-unselected-track-color: var(--disabled-track-color);
    --mdc-switch-disabled-selected-track-color: var(--disabled-track-color);
    --mat-switch-disabled-unselected-track-outline-color: var(--disabled-outline-color);
    --mat-switch-disabled-selected-track-outline-color: var(--disabled-outline-color);

    --mat-switch-visible-track-opacity: 1;
    --mdc-switch-disabled-track-opacity: 1;
  }

  .mdc-form-field {
    gap: 0.5rem;
  }

  .mdc-label {
    font-weight: 500;
    font-size: 0.8125rem;
  }
}
